// extracted by mini-css-extract-plugin
export var awards = "AwardList-module--awards--i1udI";
export var column = "AwardList-module--column--Y4R9f";
export var festivalList = "AwardList-module--festival-list--TCD27";
export var festivalListItem = "AwardList-module--festival-list-item--wQMYG";
export var header = "AwardList-module--header--SHCOK";
export var linkList = "AwardList-module--link-list--nJl6t";
export var linkListItem = "AwardList-module--link-list-item--RREtA";
export var linkListTitle = "AwardList-module--link-list-title--+Dv2N";
export var nomination = "AwardList-module--nomination--PA0zw";
export var spacer = "AwardList-module--spacer--3aww6";
export var title = "AwardList-module--title--fILC9";
export var year = "AwardList-module--year--0glZP";
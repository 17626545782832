import React from "react"

import AwardList from "./AwardList"
import AwardVideo from "./AwardVideo"
import * as styles from "./Awards.module.css"

const Awards = () => {
  return (
    <main className={styles.awards}>
      <AwardVideo />
      <AwardList />
    </main>
  )
}

export default Awards

import React from "react"
import classNames from "classnames"

import { useFirstMount } from "../../contexts/FirstMountContext"

import AwardsWebM from "../../assets/videos/awards.webm"
import AwardsMP4 from "../../assets/videos/awards.mp4"
import * as styles from "./AwardVideo.module.css"

const AwardVideo = () => {
  const isFirstMount = useFirstMount("AwardVideo")

  const videos = [
    {type: "video/webm", src: AwardsWebM},
    {type: "video/mp4", src: AwardsMP4},
  ]

  return (
    <div className={classNames(styles.column, {"appear-left": isFirstMount})}>
      <div className={styles.header}>Awards</div>
      <div className={styles.videoWrapper}>
        <video autoPlay loop muted playsInline>
          {videos.map((video) => (
            <source key={video.src} src={video.src} type={video.type} />
          ))}
        </video>
      </div>
    </div>
  )
}

export default AwardVideo

import React from "react"
import classNames from "classnames"

import * as styles from "./AwardList.module.css"

import { useFirstMount } from "../../contexts/FirstMountContext"

const AwardList = () => {
  const isFirstMount = useFirstMount("AwardList")

  return (
    <div className={classNames(styles.column, {"appear-bottom": isFirstMount})}>
      <div className={styles.awards}>
        <div className={styles.title}>Short film 'Feel'</div>
        <div className={styles.year}>2020</div>
        <div className={styles.nomination}>Winner</div>
        <div className={styles.festivalList}>
          <div className={styles.festivalListItem}>Short Shot Fest (Russia)</div>
        </div>
        <div className={styles.year}>2021</div>
        <div className={styles.nomination}>Award Winner</div>
        <div className={styles.festivalList}>
          <div className={styles.festivalListItem}>Metropolis Film Festival (Italy)</div>
        </div>
        <div className={styles.nomination}>Best Supershort Film</div>
        <div className={styles.festivalList}>
          <div className={styles.festivalListItem}>Stockholm City Film Festival (Sweden)</div>
        </div>
        <div className={styles.nomination}>Best Visual Art</div>
        <div className={styles.festivalList}>
          <div className={styles.festivalListItem}>Unknown film festival (Russia)</div>
        </div>

        <div className={styles.title}>Short film 'In The Dark'</div>
        <div className={styles.year}>2022</div>
        <div className={styles.nomination}>Best Cinematography</div>
        <div className={styles.festivalList}>
          <div className={styles.festivalListItem}>Fox Film Festival (Italy)</div>
          <div className={styles.festivalListItem}>Metropolis (Italy)</div>
          <div className={styles.festivalListItem}>Sea of Art (Norway)</div>
          <div className={styles.festivalListItem}>1MFF (Serbia)</div>
          <div className={styles.festivalListItem}>Heart of European (Slovakia)</div>
        </div>
        <div className={styles.nomination}>Best Editing</div>
        <div className={styles.festivalList}>
          <div className={styles.festivalListItem}>Metropolis (Italy)</div>
        </div>
        <div className={styles.year}>2023</div>
        <div className={styles.nomination}>Best Color</div>
        <div className={styles.festivalList}>
          <div className={styles.festivalListItem}>Creative Film Awards Los Angeles (USA)</div>
        </div>
        <div className={styles.nomination}>Special mention</div>
        <div className={styles.festivalList}>
          <div className={styles.festivalListItem}>Filmstrip International Film Festival (Romania)</div>
        </div>
        <div className={styles.spacer}></div>
        <div className={styles.linkListTitle}>Publications</div>
        <div className={styles.linkList}>
          <div className={styles.linkListItem}>
            <a href="https://www.beyondtheshort.com/short-film/into-the-dark-roman-duneshenko" target="_blank">
              Beyond the short
            </a>
          </div>
          <div className={styles.linkListItem}>
            <a href="https://filmshortage.com/shorts/in-the-dark/" target="_blank">
              Film shortage
            </a>
          </div>
          <div className={styles.linkListItem}>
            <a href="https://shortedfilms.com/short-films/in-the-dark-guilt-religion/" target="_blank">
              SHORTED
            </a>
          </div>
          <div className={styles.linkListItem}>
            <a href="https://retrospectiveofjupiter.com/films/in-the-dark/" target="_blank">
              Retrospective of Jupiter
            </a>
          </div>
        </div>

        <div className={styles.title}>Short film 'Come Back'</div>
        <div className={styles.year}>2023</div>
        <div className={styles.nomination}>Best Short Film</div>
        <div className={styles.festivalList}>
          <div className={styles.festivalListItem}>DMFF (USA)</div>
        </div>
        <div className={styles.nomination}>Special Mention</div>
        <div className={styles.festivalList}>
          <div className={styles.festivalListItem}>Boundless Film Festival (UK)</div>
        </div>
        <div className={styles.nomination}>Best Director</div>
        <div className={styles.festivalList}>
          <div className={styles.festivalListItem}>Change the Script (Canada)</div>
        </div>
        <div className={styles.year}>2024</div>
        <div className={styles.nomination}>Best Editing</div>
        <div className={styles.festivalList}>
          <div className={styles.festivalListItem}>Metropolis Film Festival (Italy)</div>
        </div>
        <div className={styles.nomination}>Special Jury Award</div>
        <div className={styles.festivalList}>
          <div className={styles.festivalListItem}>Nitiin International Film Festival (Malaysia)</div>
        </div>
        <div className={styles.nomination}>Best Director</div>
        <div className={styles.festivalList}>
          <div className={styles.festivalListItem}>Metropolis Film Festival (Italy)</div>
        </div>
        <div className={styles.nomination}>Best Micro short Film</div>
        <div className={styles.festivalList}>
          <div className={styles.festivalListItem}>Ural Shots (Russia)</div>
        </div>
        <div className={styles.nomination}>Best Micro short Film</div>
        <div className={styles.festivalList}>
          <div className={styles.festivalListItem}>Metropolis Film Festival (Italy)</div>
        </div>
        <div className={styles.nomination}>Best Color in&nbsp;Short&nbsp;Film</div>
        <div className={styles.festivalList}>
          <div className={styles.festivalListItem}>Colorist Awards (UK)</div>
        </div>
        <div className={styles.nomination}>Best Cinematography</div>
        <div className={styles.festivalList}>
          <div className={styles.festivalListItem}>Metropolis Film Festival (Italy)</div>
        </div>
        <div className={styles.spacer}></div>
        <div className={styles.linkListTitle}>Publications</div>
        <div className={styles.linkList}>
          <div className={styles.linkListItem}>
            <a href="https:/www.beyondtheshort.com/short-film/come-back-by-roman-duneshenko" target="_blank">
              Beyond the short
            </a>
          </div>
          <div className={styles.linkListItem}>
            <a href="https://filmshortage.com/dailyshortpicks/come-back/" target="_blank">
              Film shortage
            </a>
          </div>
          <div className={styles.linkListItem}>
            <a href="https://shortedfilms.com/short-films/come-back-domestic-abuse/" target="_blank">
              SHORTED
            </a>
          </div>
          <div className={styles.linkListItem}>
            <a href="https://retrospectiveofjupiter.com/films/come-back/" target="_blank">
              Retrospective of Jupiter
            </a>
          </div>
          <div className={styles.linkListItem}>
            <a href="https://shortedfilms.com/short-films/come-back-domestic-abuse/" target="_blank">
              Short films matter
            </a>
          </div>
        </div>
        <div className={styles.spacer}></div>
        <div className={styles.linkListTitle}>Streaming services</div>
        <div className={styles.linkList}>
          <div className={styles.linkListItem}>
            <a href="https://e2ac.vhx.tv/videos/come-back" target="_blank">
              E2AC+
            </a>
          </div>
          <div className={styles.linkListItem}>
            <a href="https://weshort.com/collection?s=6739" target="_blank">
              WESHORT
            </a>
          </div>
        </div>

        <div className={styles.title}>Experimental Animation Film 'Create!'</div>
        <div className={styles.linkListTitle}>Publications</div>
        <div className={styles.linkList}>
          <div className={styles.linkListItem}>
            <a href="https://www.instagram.com/p/DAH2HNisQmV/?igsh=MWZ2bWFhajFsZDM4ZQ==" target="_blank">
              Eyecandy
            </a>
          </div>
          <div className={styles.linkListItem}>
            <a href="https://www.beyondtheshort.com/art-experimental/createbyromanduneshenko" target="_blank">
              Beyond the short
            </a>
          </div>
          <div className={styles.linkListItem}>
            <a href="https://www.curationhour.com/featured/create" target="_blank">
              Curation hour
            </a>
          </div>
          <div className={styles.linkListItem}>
            <a href="https://filmshortage.com/dailyshortpicks/create/" target="_blank">
              Film shortage
            </a>
          </div>
          <div className={styles.linkListItem}>
            <a href="https://shortedfilms.com/short-films/create-inspiration-hope/" target="_blank">
              SHORTED
            </a>
          </div>
          <div className={styles.linkListItem}>
            <a href="https://retrospectiveofjupiter.com/films/create/" target="_blank">
              Retrospective of Jupiter
            </a>
          </div>
          <div className={styles.linkListItem}>
            <a href="https://shortfilmsmatter.com/13775/create/" target="_blank">
              Short films matter
            </a>
          </div>
          <div className={styles.linkListItem}>
            <a href="https://www.instagram.com/reel/DAz6sfAyE1R/?igsh=dzZneXpmNGZvbG5o" target="_blank">
              Good Ads Matter
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AwardList
